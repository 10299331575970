import React, { Component } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { DebounceInput } from 'react-debounce-input';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import ReactQuill from 'react-quill';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { getProjectsAndCrs, getBillableTimeEntries, getForecastRevenue, saveInvoiceRequest, updateInvoiceRequest, setInvoiceWorkflow, getPendingApprovalTimeEntries, updateMilestoneFile } from './InvoiceService';
import { selectInvoiceRequestWorkflow } from "../workflow/WorkflowService";
import { uploadFile, deleteFileById } from '../../integration/fileUpload/FileUploadService';
import { formatNumber, reStructureDate } from '../../integration/CommonUtils';
import { getCurrencies } from '../../masterData/common/CommonService';
import { retrieveConversionRateIntegrated } from '../../integration/ProjectManagementService';
import CustomCalendar from './CustomCalendar';
import { Redirect } from 'react-router';
import { Tooltip } from 'reactstrap';
import Loader from '../../integration/components/Loader';
import SuccessImg from '../../../src/ui/static/images/checked.svg';
import ErrorImg from '../../../src/ui/static/images/cancel.svg';
import { LOG_TYPES } from "../../integration/IntegrationEnums";
import * as loggerService from "../../integration/LoggerService";
import moment from 'moment';
import 'moment-timezone';
const apiUrl = require('../../../src/config').get(process.env.REACT_APP_ENV).apiUrl;

class CreateInvoiceRequest extends Component {
  _export;

  isMount = false;

  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.state = {
      projectsAndCrs: [],
      projectsAndCrsAll: [],
      selectedprojectOrCr: null,
      invoiceStartDate: null,
      invoiceEndDate: null,
      invoiceDescription: null,
      invoiceRemarks: null,
      opid: null,
      paymentMethod: null,
      paymentMethodName: null,
      billingRegion: null,
      engagementType: null,
      customer: null,
      poNumber: null,
      selectedCurrency: null,
      milestones: [],
      milestonesAll: [],
      selectedMilestone: null,
      milestoneRevenue: null,
      actualRevenue: null,
      forecastRevenue: null,
      timeentries: [],
      loading: false,
      isErrorMessageVisible: false,
      showErrorImage: false,
      showSuccessImage: false,
      visible: false,
      showInvoiceRequstWorkflowSelectDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      matchingWorkflowList: [],
      isClickedApprovalBtn: false,
      selectedWorkFlowId: null,
      invoiceRequest: null,
      minStartDate: null,
      maxStartDate: null,
      navigateToDashboard: false,
      projectsOrCrToolTipOpen: false,
      milestoneToolTipOpen: false,
      opidToolTipOpen: false,
      paymentMethodToolTipOpen: false,
      billingRegionToolTipOpen: false,
      engagementTypeToolTipOpen: false,
      customerToolTipOpen: false,
      poNumberToolTipOpen: false,
      currencyToolTipOpen: false,
      navigateToIRSearch: false,
      visibleSuccessPopUp: false,
      showConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,
      isPONumberRequired: false,
      isCustomerApprovalRequired: false,
      selectedFile: null,
      documentIdToDelete: null,
      isCannotUpload: true,
      skip: 0,
      take: 20,
      billingEntityToolTipOpen: false,
      billingEntityValue: null,
      actualRevenueFormat: null,
      forecastRevenueFormat: null,
      invoicingMonth: null,
      decimalPlaces: 0,
      isBillingEntityErrorMessageVisible: false,
      isPONumberErrorMessageVisible: false,
      currencyValues: [],
      currencyValuesAll: [],
      initialMilestoneRevenue: null,
      discountAmount: null,
      discountAmountFormat: null,
      discountPercentage: null,
      timeBasedRevenue: null,
      timeBasedRevenueFormat: null,
      sort: [
        { field: 'updatedAt', dir: 'desc' }
      ],
    };
  }

  async componentDidMount() {
    this.isMount = true;
    this.props.onHeaderTitleChange(
      this.props.location.action === 'view' ? 'View Invoice Request' : 
      this.props.location.action === 'edit' ? 'Edit Invoice Request' : 
      'Create Invoice Request'
    );
    await this.setState({
      loading: true
    });
    await this.populateProjectAndCrs();
    await this.populateCurrencies();
    if (this.props.location.action === 'edit' || this.props.location.action === 'view') {
      await this.populateEditInvoiceDetails();
    }
    if (this.props.location.action === 'pendingIR') {
      await this.populatePendingInvoiceDetails();
    }
    await this.setState({
      loading: false
    });
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  populateProjectAndCrs = async() => {
    await getProjectsAndCrs()
      .then(res => {
        if (this.isMount) {
          this.setState({
            projectsAndCrs: res.data,
            projectsAndCrsAll: res.data
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateCurrencies = async() => {
    await getCurrencies()
      .then(res => {
        if (res.data && res.data.length > 0) {
          if (this.isMount) {
            this.setState({
              currencyValues: res.data,
              currencyValuesAll: res.data
            });
          }
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateTimeEntries = async() => {
    await this.setState({
      loading: true
    });
    const timeEntryObj = {
      ProjectId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.ProjectId : this.state.selectedprojectOrCr.id,
      CrId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.id : null,
      ProjectOrCrName: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.CRName : this.state.selectedprojectOrCr.ProjectName,
      Type: this.state.selectedprojectOrCr.ProjectType,
      CurrencyId: this.state.selectedCurrency ? this.state.selectedCurrency.id : this.state.selectedprojectOrCr.Region.CurrencyId,
      ProjectStartDate: moment(reStructureDate(this.state.selectedprojectOrCr.StartDate)).format('YYYY-MM-DD'),
      StartDate: moment(reStructureDate(this.state.invoiceStartDate)).format('YYYY-MM-DD'),
      EndDate: moment(reStructureDate(this.state.invoiceEndDate)).format('YYYY-MM-DD'),
      InvoiceRequestId: this.state.invoiceRequest && 
        (this.state.invoiceRequest.Status === 2 || this.state.invoiceRequest.Status === 3 || this.state.invoiceRequest.Status === 6) ? 
        this.state.invoiceRequest.id : null
    }
    await getBillableTimeEntries(timeEntryObj)
      .then(res => {
        if (this.isMount) {
          this.setState({
            timeentries: res.data.TimeEntries,
            actualRevenue: res.data.ActualRevenue,
            actualRevenueFormat: formatNumber(Number(res.data.ActualRevenue)),
            timeBasedRevenue: res.data.ActualRevenue,
            timeBasedRevenueFormat: formatNumber(Number(res.data.ActualRevenue)),
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      await this.setState({
        loading: false
      });
  };

  getForecastRevenue = async() => {
    await this.setState({
      loading: true
    });
    const timeEntryObj = {
      ProjectId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.ProjectId : this.state.selectedprojectOrCr.id,
      CrId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.id : null,
      Type: this.state.selectedprojectOrCr.ProjectType,
      StartDate: moment(reStructureDate(this.state.invoiceStartDate)).format('YYYY-MM-DD'),
      EndDate: moment(reStructureDate(this.state.invoiceEndDate)).format('YYYY-MM-DD'),
      CurrencyId: this.state.selectedCurrency ? this.state.selectedCurrency.id : this.state.selectedprojectOrCr.Region.CurrencyId,
      ProjectStartDate: moment(reStructureDate(this.state.selectedprojectOrCr.StartDate)).format('YYYY-MM-DD'),
    }
    await getForecastRevenue(timeEntryObj)
      .then(res => {
        if (this.isMount) {
          this.setState({
            forecastRevenue: res.data.ForecastRevenue,
            forecastRevenueFormat: formatNumber(Number(res.data.ForecastRevenue)),
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      await this.setState({
        loading: false
      });
  };

  getPendingApprovalTimeEntries = async() => {
    await this.setState({
      loading: true
    });
    let pendingAppovalTimeEntries = false;
    const timeEntryObj = {
      ProjectId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.ProjectId : this.state.selectedprojectOrCr.id,
      CrId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.id : null,
      Type: this.state.selectedprojectOrCr.ProjectType,
      StartDate: moment(reStructureDate(this.state.invoiceStartDate)).format('YYYY-MM-DD'),
      EndDate: moment(reStructureDate(this.state.invoiceEndDate)).format('YYYY-MM-DD'),
      InvoiceRequestId: null
    }
    await getPendingApprovalTimeEntries(timeEntryObj)
      .then(res => {
        if (this.isMount) {
          if (res.data && res.data.length > 0) {
            pendingAppovalTimeEntries = true;
          }
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      await this.setState({
        loading: false
      });
      return pendingAppovalTimeEntries;
  };

  filterChangeProjects = event => {
    if (this.isMount) {
      this.setState({
        projectsAndCrs: this.filterComboData(event.filter, this.state.projectsAndCrsAll)
      });
    }
  };

  filterChangeCurrency = event => {
    if (this.isMount) {
      this.setState({
        currencyValues: this.filterComboData(event.filter, this.state.currencyValuesAll)
      });
    }
  };

  filterChangeMilestone = event => {
    if (this.isMount) {
      this.setState({
        milestones: this.filterComboData(event.filter, this.state.milestonesAll)
      });
    }
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  toggleDialog = (message, title) => {
    this.setState({
      visible: !this.state.visible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleSuccessDialog = (message, title) => {
    this.setState({
      visibleSuccessPopUp: !this.state.visibleSuccessPopUp,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleConfirmDialog = async (message, action) => {
    await this.setState({
        showConfirmDialog: !this.state.showConfirmDialog,
        confirmDialogMessage: message,
        confirmDialogAction: action
    });
};

  toggleProjectNameToolTip = () => {
    this.setState({
      projectsOrCrToolTipOpen: !this.state.projectsOrCrToolTipOpen
    });
  };

  toggleMilestoneToolTip = () => {
    this.setState({
      milestoneToolTipOpen: !this.state.milestoneToolTipOpen
    });
  };

  toggleBillingEntityToolTip = () => {
    this.setState({
      billingEntityToolTipOpen: !this.state.billingEntityToolTipOpen
    });
  };

  toggleOpidToolTip = () => {
    this.setState({
      opidToolTipOpen: !this.state.opidToolTipOpen
    });
  };

  togglePaymentMethodToolTip = () => {
    this.setState({
      paymentMethodToolTipOpen: !this.state.paymentMethodToolTipOpen
    });
  };

  toggleRegionToolTip = () => {
    this.setState({
      billingRegionToolTipOpen: !this.state.billingRegionToolTipOpen
    });
  };

  toggleProjectTypeToolTip = () => {
    this.setState({
      engagementTypeToolTipOpen: !this.state.engagementTypeToolTipOpen
    });
  };

  toggleCustomerToolTip = () => {
    this.setState({
      customerToolTipOpen: !this.state.customerToolTipOpen
    });
  };

  togglePONumberToolTip = () => {
    this.setState({
      poNumberToolTipOpen: !this.state.poNumberToolTipOpen
    });
  };

  toggleCurrencyToolTip = () => {
    this.setState({
      currencyToolTipOpen: !this.state.currencyToolTipOpen
    });
  };

  populateEditInvoiceDetails = async() => {
    const invoiceRequest = this.props.location.dataItem;
    if (invoiceRequest) {
      let selectedMilestone = null;
      let milestoneRevenue = null;
      let invoiceStartDate = null;
      let invoiceEndDate = null;
      let billingEntity = null;
      let initialMilestoneRevenue = null;
      let discountPercentage = null;
      let selectedProjectOrCr = invoiceRequest.CrId ? invoiceRequest.ChangeRequest : invoiceRequest.Project;
      selectedProjectOrCr.DisplayName = invoiceRequest.CrId ? selectedProjectOrCr.CRName : selectedProjectOrCr.ProjectName + " - " + selectedProjectOrCr.OPID;
      selectedProjectOrCr.ProjectType = invoiceRequest.CrId ? 'ChangeRequest' : 'Project';

      let opid = selectedProjectOrCr.OPID ? selectedProjectOrCr.OPID : null;
      let billingRegion = selectedProjectOrCr.Region ? selectedProjectOrCr.Region.Name : null;
      let engagementType = selectedProjectOrCr.Type ? selectedProjectOrCr.Type.Name : null;
      let customer = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.Name : null;
      let poNumber = selectedProjectOrCr.PONumber ? selectedProjectOrCr.PONumber : null;
      let isPONumberRequired = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.PONumber : false;
      let isCustomerApprovalRequired = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.CustomerApproval : false;
      let selectedCurrency = invoiceRequest.CurrencyId ? invoiceRequest.Currency : selectedProjectOrCr.Region && selectedProjectOrCr.Region.Currency ? selectedProjectOrCr.Region.Currency : null;
      let paymentMethod = null;
      let paymentMethodName = null;
      let selectedFile = null;
      let milestones = [];
      let minStartDate = selectedProjectOrCr.StartDate ? reStructureDate(selectedProjectOrCr.StartDate) : null;
      let maxStartDate = selectedProjectOrCr.UserDefinedEndDate
        ? reStructureDate(selectedProjectOrCr.UserDefinedEndDate)
        : selectedProjectOrCr.EndDate ?
        reStructureDate(selectedProjectOrCr.EndDate)
        : selectedProjectOrCr.EstimatedEndDate ?
        reStructureDate(selectedProjectOrCr.EstimatedEndDate)
        : null;

      if (invoiceRequest.CrId) {
        paymentMethod = selectedProjectOrCr.PaymentMethod;
        paymentMethodName = selectedProjectOrCr.PaymentMethod ? selectedProjectOrCr.PaymentMethod.Name : null;
      } else {
        paymentMethod = selectedProjectOrCr.PaymentMethodRecord;
        paymentMethodName = selectedProjectOrCr.PaymentMethodRecord ? selectedProjectOrCr.PaymentMethodRecord.Name : null;
      }

      if (paymentMethod && (paymentMethod.id == 1 || paymentMethod.id == 3)) {
        let invoicedMilestoneIds = selectedProjectOrCr.InvoiceRequest.map(value => value.MilestoneId);
        let validMilestones = selectedProjectOrCr.Milestone.filter(value => !invoicedMilestoneIds.includes(value.id) || value.id === invoiceRequest.MilestoneId);
        milestones = validMilestones;
        selectedMilestone = milestones.filter(value => value.id === invoiceRequest.MilestoneId)[0];
        invoiceStartDate = selectedMilestone.StartDate ? reStructureDate(selectedMilestone.StartDate) : null;
        invoiceEndDate = selectedMilestone.EndDate ? reStructureDate(selectedMilestone.EndDate) : null;
        selectedFile = selectedMilestone.FileUpload ? selectedMilestone.FileUpload : null;
        milestoneRevenue =  invoiceRequest.InvoiceAmount ? Number(invoiceRequest.InvoiceAmount).toFixed(2) : null;
        initialMilestoneRevenue = invoiceRequest.ForecastRevenue ? Number(invoiceRequest.ForecastRevenue).toFixed(2) : null;
      }  else {
        let invoiceRequests = selectedProjectOrCr.InvoiceRequest.filter(value => value.id !== invoiceRequest.id);
        if (invoiceRequests && invoiceRequests.length > 0) {
          const minDate = invoiceRequests.reduce((min, item) => {
            const currentDate = reStructureDate(item.EndDate);
            return currentDate > min ? currentDate : min;
          }, reStructureDate(invoiceRequests[0].EndDate));
          minDate.setDate(minDate.getDate() + 1);
          minStartDate = minDate;
        }
        invoiceStartDate = invoiceRequest.StartDate ? reStructureDate(invoiceRequest.StartDate) : null;
        invoiceEndDate = invoiceRequest.EndDate ? reStructureDate(invoiceRequest.EndDate) : null;
        selectedFile = invoiceRequest.FileUpload ? invoiceRequest.FileUpload : null;

        if (invoiceRequest.Status === 2 || invoiceRequest.Status === 3 || invoiceRequest.Status === 6) {
          discountPercentage = invoiceRequest.DiscountPercentage ? Number(invoiceRequest.DiscountPercentage).toFixed(2) : null;
        } else if (selectedProjectOrCr.CMSheets && selectedProjectOrCr.CMSheets[0].DiscountPercentage && selectedProjectOrCr.CMSheets[0].DiscountPercentage > 0) {
          discountPercentage = Number(selectedProjectOrCr.CMSheets[0].DiscountPercentage).toFixed(2);
        }  
      }

      if (invoiceRequest.Status === 2 || invoiceRequest.Status === 3 || invoiceRequest.Status === 6) {
        billingEntity = invoiceRequest.LegalEntity ? invoiceRequest.LegalEntity : null;
        poNumber = invoiceRequest.PONumber ? invoiceRequest.PONumber : null;
      } else {
        billingEntity = selectedProjectOrCr.LegalEntity ? selectedProjectOrCr.LegalEntity : null;
      }

      await this.setState({
        invoiceRequest: invoiceRequest, 
        selectedprojectOrCr: selectedProjectOrCr,
        opid: opid,
        paymentMethod: paymentMethod,
        paymentMethodName: paymentMethodName,
        billingRegion: billingRegion,
        engagementType: engagementType,
        customer: customer,
        poNumber: poNumber,
        selectedCurrency: selectedCurrency,
        milestones: milestones,
        milestonesAll: milestones,
        invoiceStartDate: invoiceStartDate,
        invoiceEndDate: invoiceEndDate,
        minStartDate: minStartDate,
        maxStartDate: maxStartDate,
        selectedMilestone: selectedMilestone,
        milestoneRevenue: milestoneRevenue,
        discountPercentage: discountPercentage,
        initialMilestoneRevenue: initialMilestoneRevenue,
        timeentries: [],
        billingEntityValue: billingEntity,
        invoicingMonth: invoiceRequest.InvoicingMonth ? reStructureDate(invoiceRequest.InvoicingMonth) : null,
        invoiceRemarks: invoiceRequest.Remarks,
        invoiceDescription: invoiceRequest.Description,
        isPONumberRequired: isPONumberRequired,
        isCustomerApprovalRequired: isCustomerApprovalRequired,
        selectedFile: selectedFile
      }, async () => {
        if (invoiceRequest.StartDate && invoiceRequest.EndDate) {
          await this.populateTimeEntries();
          if (paymentMethod && paymentMethod.id == 2) {
            await this.getForecastRevenue(); 
            if (this.state.discountPercentage) {
              await this.calculateDiscountValues();
            } 
          }
        }
      });
    }
  }

  populatePendingInvoiceDetails = async() => {
    const invoiceRequest = this.props.location.dataItem;
    if (invoiceRequest) {
      let selectedMilestone = null;
      let milestoneRevenue = null;
      let invoiceStartDate = null;
      let invoiceEndDate = null;
      let billingEntity = null;
      let selectedProjectOrCr = invoiceRequest.CrId ? invoiceRequest.ChangeRequest : invoiceRequest.Project;
      selectedProjectOrCr.DisplayName = invoiceRequest.CrId ? selectedProjectOrCr.CRName : selectedProjectOrCr.ProjectName + " - " + selectedProjectOrCr.OPID;
      selectedProjectOrCr.ProjectType = invoiceRequest.CrId ? 'ChangeRequest' : 'Project';

      let opid = selectedProjectOrCr.OPID ? selectedProjectOrCr.OPID : null;
      let billingRegion = selectedProjectOrCr.Region ? selectedProjectOrCr.Region.Name : null;
      let engagementType = selectedProjectOrCr.Type ? selectedProjectOrCr.Type.Name : null;
      let customer = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.Name : null;
      let poNumber = selectedProjectOrCr.PONumber ? selectedProjectOrCr.PONumber : null;
      let isPONumberRequired = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.PONumber : false;
      let isCustomerApprovalRequired = selectedProjectOrCr.Customer ? selectedProjectOrCr.Customer.CustomerApproval : false;
      let selectedCurrency = selectedProjectOrCr.Region && selectedProjectOrCr.Region.Currency ? selectedProjectOrCr.Region.Currency : null;
      let paymentMethod = null;
      let paymentMethodName = null;
      let discountPercentage = null;
      let milestones = [];
      let minStartDate = selectedProjectOrCr.StartDate ? reStructureDate(selectedProjectOrCr.StartDate) : null;
      let maxStartDate = selectedProjectOrCr.UserDefinedEndDate
        ? reStructureDate(selectedProjectOrCr.UserDefinedEndDate)
        : selectedProjectOrCr.EndDate ?
        reStructureDate(selectedProjectOrCr.EndDate)
        : selectedProjectOrCr.EstimatedEndDate ?
        reStructureDate(selectedProjectOrCr.EstimatedEndDate)
        : null;

      if (invoiceRequest.CrId) {
        paymentMethod = selectedProjectOrCr.PaymentMethod;
        paymentMethodName = selectedProjectOrCr.PaymentMethod ? selectedProjectOrCr.PaymentMethod.Name : null;
      } else {
        paymentMethod = selectedProjectOrCr.PaymentMethodRecord;
        paymentMethodName = selectedProjectOrCr.PaymentMethodRecord ? selectedProjectOrCr.PaymentMethodRecord.Name : null;
      }

      if (paymentMethod && (paymentMethod.id == 1 || paymentMethod.id == 3)) {
        let invoicedMilestoneIds = selectedProjectOrCr.InvoiceRequest.map(value => value.MilestoneId);
        let validMilestones = selectedProjectOrCr.Milestone.filter(value => !invoicedMilestoneIds.includes(value.id) || value.id === invoiceRequest.MilestoneId);
        milestones = validMilestones;
      }  else {
        maxStartDate = invoiceRequest.EndDate ? reStructureDate(invoiceRequest.EndDate) : null;
        minStartDate = invoiceRequest.StartDate ? reStructureDate(invoiceRequest.StartDate) : null;
        invoiceStartDate = invoiceRequest.StartDate ? reStructureDate(invoiceRequest.StartDate) : null;
        invoiceEndDate = invoiceRequest.EndDate ? reStructureDate(invoiceRequest.EndDate) : null;

        if (selectedProjectOrCr.CMSheets && selectedProjectOrCr.CMSheets[0].DiscountPercentage && selectedProjectOrCr.CMSheets[0].DiscountPercentage > 0) {
          discountPercentage = Number(selectedProjectOrCr.CMSheets[0].DiscountPercentage).toFixed(2);
        }
      }

      if (invoiceRequest.Status === 2 || invoiceRequest.Status === 3 || invoiceRequest.Status === 6) {
        billingEntity = invoiceRequest.LegalEntity ? invoiceRequest.LegalEntity : null;
        poNumber = invoiceRequest.PONumber ? invoiceRequest.PONumber : null;
      } else {
        billingEntity = selectedProjectOrCr.LegalEntity ? selectedProjectOrCr.LegalEntity : null;
      }

      await this.setState({
        selectedprojectOrCr: selectedProjectOrCr,
        opid: opid,
        paymentMethod: paymentMethod,
        paymentMethodName: paymentMethodName,
        billingRegion: billingRegion,
        engagementType: engagementType,
        customer: customer,
        poNumber: poNumber,
        selectedCurrency: selectedCurrency,
        milestones: milestones,
        milestonesAll: milestones,
        invoiceStartDate: invoiceStartDate,
        invoiceEndDate: invoiceEndDate,
        minStartDate: minStartDate,
        maxStartDate: maxStartDate,
        selectedMilestone: selectedMilestone,
        milestoneRevenue: milestoneRevenue,
        discountPercentage: discountPercentage,
        timeentries: [],
        billingEntityValue: billingEntity,
        invoicingMonth: null,
        invoiceRemarks: null,
        invoiceDescription: null,
        isPONumberRequired: isPONumberRequired,
        isCustomerApprovalRequired: isCustomerApprovalRequired
      }, async () => {
        if (invoiceRequest.StartDate && invoiceRequest.EndDate) {
          await this.populateTimeEntries();
          if (paymentMethod && paymentMethod.id == 2) {
            await this.getForecastRevenue();  
            if (this.state.discountPercentage) {
              await this.calculateDiscountValues();
            }
          }
        }
      });
    }
  }

  handleOnChangeProject = async event => {
    const value = event.target.value;

    await this.setState({ 
      selectedprojectOrCr: value
    });

    if (value) {

      let opid = value.OPID ? value.OPID : null;
      let billingRegion = value.Region ? value.Region.Name : null;
      let engagementType = value.Type ? value.Type.Name : null;
      let customer = value.Customer ? value.Customer.Name : null;
      let billingEntity = value.LegalEntity ? value.LegalEntity : null;
      let poNumber = value.PONumber ? value.PONumber : '';
      let isPONumberRequired = value.Customer ? value.Customer.PONumber : false;
      let isCustomerApprovalRequired = value.Customer ? value.Customer.CustomerApproval : false;
      let selectedCurrency = value.Region && value.Region.Currency ? value.Region.Currency : null;
      let paymentMethod = null;
      let paymentMethodName = null;
      let discountPercentage = null;
      let milestones = [];
      let minStartDate = value.StartDate ? reStructureDate(value.StartDate) : null;
      let maxStartDate = value.UserDefinedEndDate
        ? reStructureDate(value.UserDefinedEndDate)
        : value.EndDate ? reStructureDate(value.EndDate) 
        : value.EstimatedEndDate ? reStructureDate(value.EstimatedEndDate)
        : null;

      if (value.ProjectType === 'Project') {
        paymentMethod = value.PaymentMethodRecord;
        paymentMethodName = value.PaymentMethodRecord ? value.PaymentMethodRecord.Name : null;
      } else {
        paymentMethod = value.PaymentMethod;
        paymentMethodName = value.PaymentMethod ? value.PaymentMethod.Name : null;
      }

      if (paymentMethod && (paymentMethod.id == 1 || paymentMethod.id == 3)) {
        if (value.InvoiceRequest && value.InvoiceRequest.length > 0) {
          let invoicedMilestoneIds = value.InvoiceRequest.map(value => value.MilestoneId);
          let validMilestones = value.Milestone.filter(value => !invoicedMilestoneIds.includes(value.id));
          milestones = validMilestones;
        } else {
          milestones = value.Milestone;
        }
      } else {
        let invoiceRequests = value.InvoiceRequest;
        if (invoiceRequests && invoiceRequests.length > 0) {
          const minDate = invoiceRequests.reduce((min, item) => {
            const currentDate = reStructureDate(item.EndDate);
            return currentDate > min ? currentDate : min;
          }, reStructureDate(invoiceRequests[0].EndDate));
          minDate.setDate(minDate.getDate() + 1);
          minStartDate = minDate;
        }
        if (value.CMSheets && value.CMSheets[0].DiscountPercentage && value.CMSheets[0].DiscountPercentage > 0) {
          discountPercentage = Number(value.CMSheets[0].DiscountPercentage).toFixed(2);
        }
      }
  
      await this.setState({ 
        opid: opid,
        paymentMethod: paymentMethod,
        paymentMethodName: paymentMethodName,
        billingRegion: billingRegion,
        engagementType: engagementType,
        customer: customer,
        poNumber: poNumber,
        selectedCurrency: selectedCurrency,
        milestones: milestones,
        milestonesAll: milestones,
        invoiceStartDate: null,
        invoiceEndDate: null,
        minStartDate: minStartDate,
        maxStartDate: maxStartDate,
        selectedMilestone: null,
        milestoneRevenue: '',
        actualRevenue: '',
        forecastRevenue: '',
        actualRevenueFormat: '',
        forecastRevenueFormat: '',
        initialMilestoneRevenue: null,
        timeentries: [] ,
        invoiceRemarks: null,
        invoiceDescription: null,
        isPONumberRequired: isPONumberRequired,
        isCustomerApprovalRequired: isCustomerApprovalRequired,
        selectedFile: null,
        billingEntityValue: billingEntity,
        discountPercentage: discountPercentage,
        discountAmount: '',
        discountAmountFormat: '',
        timeBasedRevenue: '',
        timeBasedRevenueFormat: '',
        invoicingMonth: null
      });
    } else {
      await this.setState({ 
        opid: '',
        paymentMethod: null,
        paymentMethodName: '',
        billingRegion: '',
        engagementType: '',
        customer: '',
        poNumber: '',
        selectedCurrency: null,
        milestones: [],
        milestonesAll: [],
        invoiceStartDate: null,
        invoiceEndDate: null,
        selectedMilestone: null,
        milestoneRevenue: '',
        actualRevenue: '',
        forecastRevenue: '',
        actualRevenueFormat: '',
        forecastRevenueFormat: '',
        initialMilestoneRevenue: null,
        timeentries: [],
        invoiceRemarks: null,
        invoiceDescription: null,
        invoiceRequest: null,
        isPONumberRequired: false,
        isCustomerApprovalRequired: false,
        selectedFile: null,
        billingEntityValue: null,
        discountAmount: '',
        discountAmountFormat: '',
        discountPercentage: '',
        timeBasedRevenue: '',
        timeBasedRevenueFormat: '',
        invoicingMonth: null 
      });
    }    
  }

  calculateDiscountValues = async () => {
    const discountPercentage = this.state.discountPercentage;
    const actualRevenue = this.state.actualRevenue;
    let discountAmount = actualRevenue * (discountPercentage/100);
    let actualRevenueAfterDiscount = Number(actualRevenue - discountAmount).toFixed(2);

    await this.setState({ 
      actualRevenue: actualRevenueAfterDiscount,
      actualRevenueFormat: formatNumber(Number(actualRevenueAfterDiscount)),
      discountAmount: Number(discountAmount).toFixed(2),
      discountAmountFormat: formatNumber(Number(Number(discountAmount).toFixed(2))),
    });  

  }

  handleDateChange = async event => {
    const name = event.target.name;
    const value = event.target.value;
    if (value) {
      let date = new Date(value);
      await this.setState({ 
        [name]: date
      });  
    } else {
      await this.setState({ 
        [name]: null,
        actualRevenue: '',
        forecastRevenue: '',
        timeentries: []
      });  
    }

    if (this.state.selectedprojectOrCr && this.state.invoiceStartDate && this.state.invoiceEndDate) {
      await this.populateTimeEntries();
      await this.getForecastRevenue();
      if (this.state.paymentMethod && this.state.paymentMethod.id === 2 && this.state.discountPercentage) {
        await this.calculateDiscountValues();
      }
    }
  }

  handleOnChangeCurrency = async event => {
    const value = event.target.value;
    if (value) {
      let previousCurrency = this.state.selectedCurrency;
      let newCurrency = value;
      await this.setState({ 
        selectedCurrency: value,
      });

      if (this.state.selectedprojectOrCr && this.state.invoiceStartDate && this.state.invoiceEndDate) {
        await this.populateTimeEntries();
        await this.getForecastRevenue();
        if (this.state.paymentMethod && this.state.paymentMethod.id === 2 && this.state.discountPercentage) {
          await this.calculateDiscountValues();
        }
      }
      
      if (this.state.selectedprojectOrCr && this.state.selectedMilestone && this.state.invoiceStartDate && previousCurrency && newCurrency) {
        await this.convertMilestoneValues(previousCurrency, newCurrency);
      }

    } else {
      await this.setState({ 
        selectedCurrency: null,
      });  
    }
  }

  convertMilestoneValues = async(previousCurrency, newCurrency) => {
    const prevRate = await retrieveConversionRateIntegrated(
      previousCurrency.id,
      reStructureDate(this.state.invoiceStartDate).getMonth() + 1,
      reStructureDate(this.state.invoiceStartDate).getFullYear()
    );
    if (prevRate > 0) {
      const newRate = await retrieveConversionRateIntegrated(
        newCurrency.id,
        reStructureDate(this.state.invoiceStartDate).getMonth() + 1,
        reStructureDate(this.state.invoiceStartDate).getFullYear()
      );
      if (newRate > 0) {
        const milestoneRevenue = this.calculateNewRate(this.state.milestoneRevenue, prevRate, newRate);
        const convertedInitialMilestoneRevenue = this.calculateNewRate(this.state.initialMilestoneRevenue, prevRate, newRate);      
        if (this.isMount) {
          this.setState({
            milestoneRevenue: Number(milestoneRevenue).toFixed(2),
            initialMilestoneRevenue: Number(convertedInitialMilestoneRevenue).toFixed(2),
          });
        }
      } else {
        const message = 'Currency conversion rate not found for selected currency';
        const title = 'Error';
        this.toggleDialog(message, title);
      }
    } else {
      const message = 'Currency conversion rate not found for previously selected currency';
      const title = 'Error';
      this.toggleDialog(message, title);
    }
  };

  calculateNewRate = (value, prevRate, newRate) => {
    return value ? (value / prevRate) * newRate : 0;
  };

  handleInvoicingMonthChange = async event => {
    const value = event.target.value;
    if (value) {
      const firstDateOfMonth = new Date(value).setDate(1);
      await this.setState({ 
        invoicingMonth: new Date(firstDateOfMonth)
      });  
    } else {
      await this.setState({ 
        invoicingMonth: null,
      });  
    }
  }

  handleOnChangeMilestone = async event => {
    const value = event.target.value;
    if (value) {
      let selectedFile = value.FileUpload ? value.FileUpload : null;      
      const milestoneRevenue = value.Revenue;
      await this.setState({ 
        selectedMilestone: value,
        milestoneRevenue: milestoneRevenue ? Number(milestoneRevenue).toFixed(2) : null,
        initialMilestoneRevenue: milestoneRevenue ? Number(milestoneRevenue).toFixed(2) : null,
        invoiceStartDate: reStructureDate(value.StartDate),
        invoiceEndDate: reStructureDate(value.EndDate),
        invoiceDescription: value.Name,
        selectedFile: selectedFile
      });
      if (this.state.selectedprojectOrCr && this.state.invoiceStartDate && this.state.invoiceEndDate) {
        await this.populateTimeEntries();
      }
    } else {
      await this.setState({ 
        selectedMilestone: null,
        milestoneRevenue: '',
        initialMilestoneRevenue: null,
        invoiceStartDate: null,
        invoiceEndDate: null,
        selectedFile: null,
        invoiceDescription: null
      });
    }
  }

  handleDescription = comment => {
    this.setState({
      invoiceDescription: comment
    });
  };

  handleRemarks = comment => {
    this.setState({
      invoiceRemarks: comment
    });
  };

  handleChangeMilestoneRevenue = async event => {
    const value = event.target.value;
    if (value) {
      await this.setState({ 
        milestoneRevenue: value
      });
    } else {
      await this.setState({ 
        milestoneRevenue: null
      });
    }
  };

  validations = async() => {
    if (!this.state.selectedprojectOrCr) {
      return true;
    } else if ((this.state.paymentMethod && this.state.paymentMethod.id == 2) && !(this.state.invoiceStartDate && this.state.invoiceEndDate)) {
      return true;
    } else if ((this.state.paymentMethod && this.state.paymentMethod.id !== 2) && !(this.state.selectedMilestone && this.state.milestoneRevenue)) {
      return true;
    } else if ((this.state.paymentMethod && this.state.paymentMethod.id == 2) && (!this.state.invoiceDescription || this.state.invoiceDescription === '' ||  this.state.invoiceDescription === '<p><br></p>')) {
      return true;
    } else if ((this.state.paymentMethod && this.state.paymentMethod.id !== 2) && (!this.state.milestoneRevenue || this.state.milestoneRevenue <= 0)) {
      return true;
    } else if (!this.state.invoicingMonth) {
      return true;
    } else if (!this.state.selectedCurrency) {
      return true;
    } else {
      return false;
    }
  }

  validateInvoiceDates = async() => {
    if (this.state.paymentMethod && this.state.paymentMethod.id === 2) {
      let minStartDate = moment(reStructureDate(this.state.minStartDate)).format('YYYY-MM-DD');
      let invoiceStartDate = moment(reStructureDate(this.state.invoiceStartDate)).format('YYYY-MM-DD');
      let invoiceEndDate = moment(reStructureDate(this.state.invoiceEndDate)).format('YYYY-MM-DD');

      if (new Date(invoiceStartDate) < new Date(minStartDate) || new Date(invoiceEndDate) < new Date(minStartDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validateCustomerPONumber = async() => {
    if (this.state.isPONumberRequired) {
      if (this.state.poNumber) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  validateCustomerApproval = async() => {
    if ((this.state.paymentMethod && (this.state.paymentMethod.id === 1 || this.state.paymentMethod.id === 3)) || 
      this.state.isCustomerApprovalRequired) {
      if (this.state.selectedFile && this.state.selectedFile.id) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  saveInvoiceRequest = async() => {
    await this.setState({
      loading: true
    });
    if (await this.validations()) {
      this.setState({
        isErrorMessageVisible: true,
        showErrorImage: true,
        showSuccessImage: false,
      });
      this.toggleDialog('Please fill the mandatory fields to continue.', 'Error');
    } else if (await this.validateCustomerApproval()){
      this.setState({
        isErrorMessageVisible: true,
        showErrorImage: true,
        showSuccessImage: false,
      });
      this.toggleDialog('Please fill the mandatory fields to continue.', 'Error');
    } else if (await this.validateInvoiceDates()){
      this.setState({
        isErrorMessageVisible: true,
        showErrorImage: true,
        showSuccessImage: false,
      });
      this.toggleDialog('Please check the invoice request date range to continue', 'Error');
    } else {
      let invoiceAmount = null;
      let forecastRevenue = null;
      let milestoneId = null;
      let invoiceStartDate = null;
      let invoiceEndDate = null;
      let approvalFileId = null;
      let milestoneApprovalFileId = null;

      if (this.state.paymentMethod && this.state.paymentMethod.id === 2) {
        invoiceAmount = this.state.actualRevenue;
        forecastRevenue = this.state.forecastRevenue;
        invoiceStartDate = moment(reStructureDate(this.state.invoiceStartDate)).format('YYYY-MM-DD');
        invoiceEndDate = moment(reStructureDate(this.state.invoiceEndDate)).format('YYYY-MM-DD');
        approvalFileId = this.state.selectedFile && this.state.selectedFile.id ? this.state.selectedFile.id : null;
      } else {
        invoiceAmount = this.state.milestoneRevenue;
        milestoneId = this.state.selectedMilestone ? this.state.selectedMilestone.id : null;
        forecastRevenue = this.state.initialMilestoneRevenue ? this.state.initialMilestoneRevenue : null;
        invoiceStartDate = this.state.selectedMilestone ? moment(reStructureDate(this.state.selectedMilestone.StartDate)).format('YYYY-MM-DD') : null;
        invoiceEndDate = this.state.selectedMilestone ? moment(reStructureDate(this.state.selectedMilestone.EndDate)).format('YYYY-MM-DD') : null;
        milestoneApprovalFileId = this.state.selectedFile && this.state.selectedFile.id ? this.state.selectedFile.id : null;
      }

      const InvoiceObj = {
        id: this.state.invoiceRequest ? this.state.invoiceRequest.id : null,
        ProjectId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.ProjectId : this.state.selectedprojectOrCr.id,
        CrId: this.state.selectedprojectOrCr.ProjectType === "ChangeRequest" ? this.state.selectedprojectOrCr.id : null,
        Type: this.state.selectedprojectOrCr.ProjectType,
        StartDate: invoiceStartDate,
        EndDate: invoiceEndDate,
        MilestoneId: milestoneId,
        InvoiceAmount: invoiceAmount,
        ForecastRevenue: forecastRevenue,
        Description: this.state.invoiceDescription,
        Remarks: this.state.invoiceRemarks,
        UniqueId: this.state.selectedprojectOrCr.UniqueId,
        ApprovalFileId: approvalFileId,
        MilestoneApprovalFileId: milestoneApprovalFileId,
        BillingEntityId: this.state.billingEntityValue ? this.state.billingEntityValue.id : null,
        InvoicingMonth: this.state.invoicingMonth ? moment(reStructureDate(this.state.invoicingMonth)).format('YYYY-MM-DD'): null,
        PONumber: this.state.poNumber ? this.state.poNumber : null,
        TimeBasedRevenue: this.state.timeBasedRevenue,
        CurrencyId: this.state.selectedCurrency ? this.state.selectedCurrency.id : this.state.selectedprojectOrCr.Region.CurrencyId,
        DiscountPercentage: this.state.discountPercentage ? this.state.discountPercentage : null,
        DiscountAmount: this.state.discountAmount ? this.state.discountAmount : null
      }
      if (this.props.location.action === 'edit') {
        const invoiceId = this.props.location.dataItem.id;
        await updateInvoiceRequest(invoiceId, InvoiceObj)
        .then(res => {
          this.setState({
            isErrorMessageVisible: false,
            isBillingEntityErrorMessageVisible: false,
            isPONumberErrorMessageVisible: false,
            showSuccessImage: true,
            showErrorImage: false
          });
          if (!this.state.isClickedApprovalBtn) {
            this.toggleSuccessDialog('Saved Successfully', 'Success');
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });  

      } else {
        await saveInvoiceRequest(InvoiceObj)
        .then(res => {
          this.setState({
            invoiceRequest: res.data,
            isErrorMessageVisible: false,
            isBillingEntityErrorMessageVisible: false,
            isPONumberErrorMessageVisible: false,
            showSuccessImage: true,
            showErrorImage: false
          });
          if (!this.state.isClickedApprovalBtn) {
            this.toggleSuccessDialog('Saved Successfully', 'Success');
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });  
      }
    }

    await this.setState({
      loading: false
    });
  }

  validateProperty = value => {
    if (value) {
        return 'd-none';
    } else {
        return 'inline-error';
    }
  };

  validateMilestoneRevenue = value => {
    if (value && value > 0) {
        return 'd-none';
    } else {
        return 'inline-error';
    }
  };

  validateDescription = value => {
    if (value && value !== '' && value !== '<p><br></p>') {
        return 'd-none';
    } else {
        return 'inline-error';
    }
  };

  toggleInvoiceRequestWorkflowSelectDialog = () => {
    this.setState({
      showInvoiceRequstWorkflowSelectDialog: !this.state.showInvoiceRequstWorkflowSelectDialog
    });
  };

  setSelectedWorkflowId = async workflowId => {
    await this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  submitInvoiceRequestValidations = async() => {
    await this.setState({
      isClickedApprovalBtn: true
    });

    await this.saveInvoiceRequest();

    if (!this.state.billingEntityValue) {
      this.setState({
        isBillingEntityErrorMessageVisible: true,
        showErrorImage: true,
        showSuccessImage: false,
        isClickedApprovalBtn: false
      });
      this.toggleDialog('Please fill the mandatory fields to continue.', 'Error');
      return;
    } 

    if (await this.validateCustomerPONumber()){
      this.setState({
        isPONumberErrorMessageVisible: true,
        showErrorImage: true,
        showSuccessImage: false,
        isClickedApprovalBtn: false
      });
      this.toggleDialog('Please fill the mandatory fields to continue.', 'Error');
      return;
    }

    if (!this.state.isErrorMessageVisible) {
      if (this.state.paymentMethod && this.state.paymentMethod.id === 2) {
        if (await this.getPendingApprovalTimeEntries()) {
          this.setState({
            showErrorImage: true,
            showSuccessImage: false,
            isClickedApprovalBtn: false
          });
          this.toggleConfirmDialog('There are unapproved time entries for this Project/CR within the selected date range. Do you want to proceed with submitting the invoice request?', this.submitInvoiceRequest);
          return;
        }
      }
      if (this.state.paymentMethod && this.state.paymentMethod.id !== 2) {
        if (Number(this.state.actualRevenue) > Number(this.state.milestoneRevenue)) {
          this.setState({
            showErrorImage: true,
            showSuccessImage: false,
            isClickedApprovalBtn: false
          });
          this.toggleConfirmDialog(`Actual Revenue ${this.state.selectedCurrency.Code} ${this.state.actualRevenue} exceeds Planned Revenue ${this.state.selectedCurrency.Code} ${this.state.milestoneRevenue}. Do you want to proceed with submitting the invoice request?`, 
            this.submitInvoiceRequest);
          return;
        }
      }
      await this.submitInvoiceRequest();
    }
    await this.setState({
      isClickedApprovalBtn: false
    });
  }

  submitInvoiceRequest = async() => {
    if (!this.state.isErrorMessageVisible) {
      if (this.state.showConfirmDialog) {
        await this.toggleConfirmDialog("", null)
      }
      const selectedProjectOrCR = this.state.selectedprojectOrCr;
      const projData = {
        Region: selectedProjectOrCR.Region.id
      }
      await selectInvoiceRequestWorkflow(projData).then(res => {

        if (res.data.length > 1) {
          this.setState(
            {
              matchingWorkflowList: res.data
            },
            () => {
              this.toggleInvoiceRequestWorkflowSelectDialog();
            }
          );
        } else if (res.data.length === 1) {
          this.setState(
            {
              selectedWorkFlowId: res.data[0].id
            },
            () => {
              this.setWorkflowInvoiceRequest();
            }
          );
        } else {
          const message =
            'This request has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue.';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isErrorMessageVisible: false
          });
          this.toggleDialog(message, title);
        }
      });
    }
    await this.setState({
      isClickedApprovalBtn: false
    });
  }

  setWorkflowInvoiceRequest = async() => {
    await this.setState({
      showInvoiceRequstWorkflowSelectDialog: false,
      loading: true
    });

    const selectedProjectOrCR = this.state.selectedprojectOrCr;

    const timeentryIds = this.state.timeentries.map(val => val.id)

    if (selectedProjectOrCR) {
      const workflowData = {
        InvoiceId: this.state.invoiceRequest.id,
        ProjectId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.ProjectId : selectedProjectOrCR.id,
        CrId: selectedProjectOrCR.ProjectId ? selectedProjectOrCR.id : null,
        WorkflowId: this.state.selectedWorkFlowId,
        Type: selectedProjectOrCR.ProjectType,
        ProjectOrCR: selectedProjectOrCR,
        InvoiceEndDate: moment(reStructureDate(this.state.invoiceRequest.InvoicingMonth)).format('YYYY-MM-DD'),
        TimeEntryIds: timeentryIds,
        SubmittedDate: moment(new Date()).format('YYYY-MM-DD')
      };
      await setInvoiceWorkflow(workflowData)
      .then(async res => {
        await this.setState({
          selectedWorkFlowId: null,
          showErrorImage: false,
          showSuccessImage: true
        });
        this.navigateToDashboard();
      })
      .catch(error => {
        this.setState({
          loading: false,
          showErrorImage: true,
          showSuccessImage: false
        });
        this.toggleDialog(error, 'Error');
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });     
    }
    await this.setState({
      loading: false
    });
  }

  handleFileUpload = async (event) => {
    if((event.target.files[0].type === 'application/pdf' || 
        event.target.files[0].type === 'image/jpeg' || 
        event.target.files[0].type === 'image/png' || 
        event.target.files[0].type === 'application/vnd.ms-project' ||
        event.target.files[0].type === "application/msword" ||
        event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        event.target.files[0].type === 'application/vnd.ms-powerpoint' ||
        event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
        event.target.files[0].type === 'application/vnd.ms-excel' || 
        event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' ||
        event.target.files[0].type === "application/vnd.ms-outlook" ||
        event.target.files[0].type === "application/msoutlook" ||
        event.target.files[0].name.includes('.msg') ||
        event.target.files[0].name.includes('.htm') ||
        event.target.files[0].name.includes('.mpp')) ) {
          await this.setState({
              selectedFile: event.target.files[0],
              loaded: 0,
              isCannotUpload: false,
              loading: true
          });
    }
    else {
        const message = "Attachment can be only added as a 'pdf, png, jpg, doc, docx, dotx, xls, xlsx, ppt, pptx, or mpp' file";
        const title = 'Error';
        await this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            isCannotUpload: true
        });
        await this.toggleDialog(message, title);
    }

    if (this.state.isCannotUpload === false) {
        // UPLOAD LOGIC
        let refType = this.state.paymentMethod && this.state.paymentMethod.id === 2 ? 'CustomerApprovalIR' : 'Milestone';
        var form_data = new FormData();
        form_data.append('file', this.state.selectedFile);
        form_data.append('referenceType', refType);
        form_data.append('referenceId', this.state.selectedprojectOrCr ? this.state.selectedprojectOrCr.id : null);

        await uploadFile(form_data).then(res => {
            if (res) {
              this.setState({
                selectedFile: res.data,
                loading: false,
                isFileChanged: true,
                showSuccessImage: true,
                showErrorImage: false,
              });
            }
            this.toggleDialog('File has been uploaded successfully', 'Upload Status');
        }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            this.setState({
                loading: false
            });
        });
    }
  };

  handleDeleteUploadFile = () => {
    const selectedFile = this.state.selectedFile
    if(this.isMount){
        this.setState({
            documentIdToDelete: selectedFile.id
        }, () => {
            this.toggleConfirmDialog('Are you sure you want to delete the uploaded document?', this.onDeleteOk);
        });
    }
};

  onDeleteOk = async () => {
    this.setState({
        loading: true,
    });
    if (this.state.selectedMilestone && this.state.paymentMethod && this.state.paymentMethod.id !== 2) {
      await updateMilestoneFile(this.state.selectedMilestone.id).then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
        });
      }).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    await deleteFileById(this.state.documentIdToDelete).then(response => {
        if(this.isMount){
            this.setState({
                documentIdToDelete: null,
                selectedFile: null,
                showSuccessImage: true,
                showErrorImage: false,
                loading: false
            });
        }
        this.toggleConfirmDialog('', null);
        this.toggleDialog('Successfully deleted', 'Success');
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
    }).catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.setState({
            showSuccessImage: false,
            showErrorImage: true,
            loading: false
        })
        this.toggleDialog(error.response.data, 'Error');
    });
  };

  navigateToDashboard = () => {
    this.setState({
      navigateToDashboard: true
    });
  };

  navigateToIRSearch = () => {
    this.setState({
      navigateToIRSearch: true
    });
  };

  pageChange = event => {
    if (this.isMount) {
      this.setState({
        skip: event.page.skip,
        take: event.page.take
      });
    }
  };

  render() {

    if (this.state.navigateToDashboard === true) {
      return <Redirect to="/" />;
    }

    if (this.state.navigateToIRSearch === true) {
      return <Redirect to="/projects/invoice/invoicehistory" />;
    }

    return (
      <div className="main-card">
        <div className="row">
          <div className="col-md-8">
            <div className="main-heading">
              {this.props.location.action === 'view' ? 'View Invoice Request' : 
              this.props.location.action === 'edit' ? 'Edit Invoice Request' : 
              'Create Invoice Request'}
            </div>
          </div>
        </div> 

        <div className="row">
          <div className="col-md-3">
            <div className="d-block">
              <div className="mandatory"><label>Project/CR Name:</label></div>
            </div>
            <div className="d-block">
              <div id="projectsOrCrToolTip">
                <ComboBox
                    placeholder="Please Select"
                    data={this.state.projectsAndCrs}
                    value={this.state.selectedprojectOrCr}
                    textField="DisplayName"
                    dataItemKey="id"
                    onChange={this.handleOnChangeProject}
                    name="selectedprojectOrCr"
                    filterable={true}
                    onFilterChange={this.filterChangeProjects}
                    disabled={this.props.location.action === 'pendingIR' || this.props.location.action === 'edit' || this.props.location.action === 'view'}
                />
                {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.selectedprojectOrCr)}>
                        Please select a Project/CR
                    </span>
                ) : null}
              </div>
              {this.state.selectedprojectOrCr && this.state.selectedprojectOrCr.DisplayName ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.projectsOrCrToolTipOpen}
                  target="projectsOrCrToolTip"
                  toggle={this.toggleProjectNameToolTip}
                >
                  {this.state.selectedprojectOrCr.DisplayName}
                </Tooltip>
              ) : null}
            </div>
          </div>
          </div>

          {this.state.paymentMethod && this.state.paymentMethod.id === 2 && (
            <div className="row mt-3" >
              <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory" htmlFor="">Date From: </label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100"
                      name="invoiceStartDate"
                      onChange={this.handleDateChange}
                      value={this.state.invoiceStartDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      min={new Date(this.state.minStartDate)}
                      max={this.state.maxStartDate ? new Date(this.state.maxStartDate) : undefined}
                      disabled={this.props.location.action === 'view'}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.invoiceStartDate)}>
                            Please select a Start Date
                        </span>
                    ) : null}
                  </div>
                </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                  <label className="mandatory" htmlFor="">Date To:</label>
              </div>
              <div className="d-block">
                <div className="title-remove">
                  <DatePicker
                    width="100"
                    name="invoiceEndDate"
                    onChange={this.handleDateChange}
                    value={this.state.invoiceEndDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                    min={this.state.invoiceStartDate ? new Date(this.state.invoiceStartDate) : new Date(this.state.minStartDate)}
                    max={this.state.maxStartDate ? new Date(this.state.maxStartDate) : undefined}
                    disabled={this.props.location.action === 'view'}
                  />
                  {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.invoiceEndDate)}>
                          Please select a End Date
                      </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Invoice Revenue:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    disabled
                    value={this.state.actualRevenueFormat}
                    name="actualRevenue"
                    className="font-weight-bold"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <div className="d-block">
                <div className=""><label>Planned Revenue:</label></div>
              </div>
              <div className="d-block">
                <div className="">
                  <Input
                    disabled
                    value={this.state.forecastRevenueFormat}
                    name="forecastRevenue"
                    className="font-weight-bold"
                  />
                </div>
              </div>
            </div>

          </div>
          )}

          {this.state.paymentMethod && (this.state.paymentMethod.id === 1 || this.state.paymentMethod.id === 3) && (
            <div className="row mt-3">
              <div className="col-md-3">
                <div className="d-block">
                  <div className="mandatory"><label>Milestone:</label></div>
                </div>
                <div className="d-block">
                  <div id="milestoneToolTip">
                    <ComboBox
                        placeholder="Please Select"
                        data={this.state.milestones}
                        value={this.state.selectedMilestone}
                        textField="Name"
                        dataItemKey="id"
                        onChange={this.handleOnChangeMilestone}
                        name="selectedMilestone"
                        filterable={true}
                        onFilterChange={this.filterChangeMilestone}
                        disabled={this.props.location.action === 'view'}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.selectedMilestone)}>
                            Please select a Milestone
                        </span>
                    ) : null}
                  </div>
                  {this.state.selectedMilestone ? (
                    <Tooltip
                      placement="top"
                      isOpen={this.state.milestoneToolTipOpen}
                      target="milestoneToolTip"
                      toggle={this.toggleMilestoneToolTip}
                    >
                      {this.state.selectedMilestone.Name}
                    </Tooltip>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block">
                  <div className=""><label>Milestone Revenue:</label></div>
                </div>
                <div className="d-block">
                  <div className="">
                    <DebounceInput
                      width="100%"
                      value={this.state.milestoneRevenue}
                      disabled={this.props.location.action === 'view'}
                      type="number"
                      step={this.state.decimalPlaces}
                      pattern="[0-9]*"
                      className="k-textbox"
                      required={true}
                      onChange={this.handleChangeMilestoneRevenue}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                      <span className={this.validateMilestoneRevenue(this.state.milestoneRevenue)}>
                          Please enter the Milestone Revenue
                      </span>
                  ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block">
                  <label className="" htmlFor="">Milestone Start Date: </label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100"
                      name="invoiceStartDate"
                      value={this.state.invoiceStartDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={true}
                      className="font-weight-bold"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block">
                    <label className="" htmlFor="">Milestone End Date:</label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100"
                      name="invoiceEndDate"
                      value={this.state.invoiceEndDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={true}
                      className="font-weight-bold"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        {this.state.selectedprojectOrCr && (
          <div className="row mt-3">
            <div className="col-md-3">
                <div className="d-block">
                  <label className="mandatory" htmlFor="">Invoicing Month:</label>
                </div>
                <div className="d-block">
                  <div className="title-remove">
                    <DatePicker
                      width="100"
                      name="invoicingMonth"
                      calendar={CustomCalendar}
                      value={this.state.invoicingMonth}
                      format="MM/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM'}}
                      disabled={this.props.location.action === 'view'}
                      onChange={this.handleInvoicingMonthChange}
                    />
                    {this.state.isErrorMessageVisible === true ? (
                        <span className={this.validateProperty(this.state.invoicingMonth)}>
                          Please select the Invoicing Month
                      </span>
                  ) : null}
                  </div>
                </div>
              </div>

              {this.state.paymentMethod && (this.state.paymentMethod.id === 1 || this.state.paymentMethod.id === 3) && (
                <div className="col-md-3">
                  <div className="d-block">
                    <div className=""><label>Time Entry Based Revenue:</label></div>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <Input
                        disabled
                        value={this.state.timeBasedRevenueFormat}
                        name="timeBasedRevenue"
                        className="font-weight-bold"
                      />    
                    </div>
                  </div>
                </div>
              )}

              {this.state.paymentMethod && this.state.paymentMethod.id == 2 && this.state.discountPercentage && (
                <div className="col-md-3">
                  <div className="d-block">
                    <div className=""><label>Time Entry Based Revenue:</label></div>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <Input
                        disabled
                        value={this.state.timeBasedRevenueFormat}
                        name="timeBasedRevenue"
                        className="font-weight-bold"
                      />    
                    </div>
                  </div>
                </div>
              )}

              {this.state.paymentMethod && this.state.paymentMethod.id == 2 && this.state.discountPercentage && (
                <div className="col-md-3">
                  <div className="d-block">
                    <div className=""><label>Discount %:</label></div>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <Input
                        disabled
                        value={this.state.discountPercentage}
                        name="discountPercentage"
                        className="font-weight-bold"
                      />    
                    </div>
                  </div>
                </div>
              )}

              {this.state.paymentMethod && this.state.paymentMethod.id == 2 && this.state.discountPercentage && (
                <div className="col-md-3">
                  <div className="d-block">
                    <div className=""><label>Discount Amount:</label></div>
                  </div>
                  <div className="d-block">
                    <div className="">
                      <Input
                        disabled
                        value={this.state.discountAmountFormat}
                        name="discountAmount"
                        className="font-weight-bold"
                      />    
                    </div>
                  </div>
                </div>
              )}
          </div> 
        )}  

        <div className="row mt-3">
          <div className="col-md-6">
            <label htmlFor="" className={this.state.paymentMethod && this.state.paymentMethod.id == 2 ? "mandatory" : ""}>Invoice Description:</label>
          </div>
          <div className="col-md-6">
            <label htmlFor="">Remarks:</label>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <ReactQuill
              name="invoiceDescription"
              onChange={this.handleDescription}
              readOnly={this.props.location.action === 'view'}
              value={this.state.invoiceDescription}
              maxLength="1000"
              
            />
            {this.state.paymentMethod && this.state.paymentMethod.id == 2 && this.state.isErrorMessageVisible === true ? (
                <span className={this.validateDescription(this.state.invoiceDescription)}>
                    Please input a Invoice Description
                </span>
            ) : null}
          </div>
          <div className="col-md-6">
            <ReactQuill
              name="invoiceRemarks"
              onChange={this.handleRemarks}
              readOnly={this.props.location.action === 'view'}
              value={this.state.invoiceRemarks}
              maxLength="1000"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-6">
            <div className="d-flex">
              {this.state.paymentMethod && (this.state.paymentMethod.id === 1 || this.state.paymentMethod.id === 3) ?
                <div className={"mandatory"}><label>Customer Approval:</label></div> :
                <div className={this.state.isCustomerApprovalRequired ? "mandatory" : ""}><label>Customer Approval:</label></div>
              }
                {this.props.location.action !== 'view' && (
                  <div className="col-md-3 d-flex flex-col">
                      <div className="rounded-link">
                          <span className="k-icon k-i-upload"/>
                      </div>
                      <input 
                          type="file" 
                          name="file" 
                          title="Upload File"
                          className="custom-statusFile-input"
                          disabled={this.props.location.action === 'view' || !this.state.selectedprojectOrCr}
                          ref={this.fileInputRef} 
                          onChange={this.handleFileUpload}
                          value={this.state.uploadedFileId}
                          multiple={true}
                          style={{ margin: 0 }} 
                      />
                  </div> 
                )}
            </div>

            {((this.state.paymentMethod && (this.state.paymentMethod.id === 1 || this.state.paymentMethod.id === 3)) || this.state.isCustomerApprovalRequired) && 
              this.state.isErrorMessageVisible === true ? (
                <span className={this.validateProperty(this.state.selectedFile)}>
                    Please upload the Customer Approval
                </span>
            ) : null}

            {this.state.selectedFile && this.state.selectedFile.FileName && (
              <div className="row">
                <div className="col-md-4 d-flex align-items-center justify-content-between">
                {this.state.paymentMethod && this.state.paymentMethod.id === 2 ? (
                  <a href={`${apiUrl}/integration/fileUpload/downloadircustomerapproval?path=CustomerApprovalIR_${encodeURIComponent(this.state.selectedFile.FileName)}&&irFileName=${encodeURIComponent(this.state.selectedFile.FileName)}`} target="">
                      {this.state.selectedFile.FileName}
                      <span className="k-icon k-i-download ml-2"></span>
                  </a>
                  ) : (
                    <a href={`${apiUrl}/integration/fileUpload/downloadmilestone?path=Milestone_${encodeURIComponent(this.state.selectedFile.FileName)}&&milestoneFileName=${encodeURIComponent(this.state.selectedFile.FileName)}`} target="">
                        {this.state.selectedFile.FileName}
                        <span className="k-icon k-i-download ml-2"></span>
                    </a> 
                  )}
                  {this.props.location.action !== 'view' && (
                    <a onClick={this.handleDeleteUploadFile}>
                        <div className="rounded-link d-flex align-items-center cursor-pointer">
                            <span className="k-icon k-i-delete" title="Delete" />
                        </div>
                    </a>
                  )}
                </div> 
              </div>
            )}
          </div>
        </div>

        <div className="main-seperator" />

        <div className="row">

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>OP ID:</label>
              </div>
              <div className="d-block" id="opIdToolTip">
                <Input disabled value={this.state.opid} />
              </div>
              {this.state.opid ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.opidToolTipOpen}
                  target="opIdToolTip"
                  toggle={this.toggleOpidToolTip}
                >
                  {this.state.opid}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Billing Method:</label>
              </div>
              <div className="d-block" id="paymentMethodToolTip">
                <Input disabled value={this.state.paymentMethodName} />
              </div>
              {this.state.paymentMethodName ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.paymentMethodToolTipOpen}
                  target="paymentMethodToolTip"
                  toggle={this.togglePaymentMethodToolTip}
                >
                  {this.state.paymentMethodName}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Billing Region:</label>
              </div>
              <div className="d-block" id="billingRegionToolTip">
                <Input disabled value={this.state.billingRegion} />
              </div>
              {this.state.billingRegion ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.billingRegionToolTipOpen}
                  target="billingRegionToolTip"
                  toggle={this.toggleRegionToolTip}
                >
                  {this.state.billingRegion}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Engagement Type:</label>
              </div>
              <div className="d-block" id="engagementTypeToolTip">
                <Input disabled value={this.state.engagementType} />
              </div>
              {this.state.engagementType ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.engagementTypeToolTipOpen}
                  target="engagementTypeToolTip"
                  toggle={this.toggleProjectTypeToolTip}
                >
                  {this.state.engagementType}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block">
                <label>Customer:</label>
              </div>
              <div className="d-block" id="customerToolTip">
                <Input disabled value={this.state.customer} />
              </div>
              {this.state.customer ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.customerToolTipOpen}
                  target="customerToolTip"
                  toggle={this.toggleCustomerToolTip}
                >
                  {this.state.customer}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className={this.state.isPONumberRequired ? "mandatory" : ""}>
                <label>PO Number:</label>
              </div>
              <div className="d-block" id="poNumberToolTip">
                <Input disabled value={this.state.poNumber} />
                {this.state.isPONumberRequired && this.state.isPONumberErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.poNumber)}>
                        Please update the PO Number
                    </span>
                ) : null}
              </div>
              {this.state.poNumber ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.poNumberToolTipOpen}
                  target="poNumberToolTip"
                  toggle={this.togglePONumberToolTip}
                >
                  {this.state.poNumber}
                </Tooltip>
              ) : null}
            </div>
          </div>
          

        </div>

        <div className="row">

          <div className="col-md-2">
            <div className="">
              <div className="d-block mandatory">
                <label>Currency:</label>
              </div>
              <div className="d-block" id="currencyToolTip">
                <ComboBox
                    placeholder="Please Select"
                    data={this.state.currencyValues}
                    value={this.state.selectedCurrency}
                    textField="Code"
                    dataItemKey="id"
                    onChange={this.handleOnChangeCurrency}
                    name="selectedCurrency"
                    filterable={true}
                    onFilterChange={this.filterChangeCurrency}
                    disabled={this.props.location.action === 'view'}
                />
                {this.state.isErrorMessageVisible === true ? (
                    <span className={this.validateProperty(this.state.selectedCurrency)}>
                        Please select a Currency
                    </span>
                ) : null}
              </div>
              {this.state.selectedCurrency ? (
                <Tooltip
                  placement="top"
                  isOpen={this.state.currencyToolTipOpen}
                  target="currencyToolTip"
                  toggle={this.toggleCurrencyToolTip}
                >
                  {this.state.selectedCurrency.Code}
                </Tooltip>
              ) : null}
            </div>
          </div>

          <div className="col-md-2">
            <div className="">
              <div className="d-block mandatory">
                <label>Billing Entity:</label>
              </div>
              <div className="d-block" id="billingEntityToolTip">
                <Input disabled value={this.state.billingEntityValue ? this.state.billingEntityValue.Name : ''} />
              </div>
              {this.state.isBillingEntityErrorMessageVisible === true ? (
                  <span className={this.validateProperty(this.state.billingEntityValue)}>
                      Please select the Billing Entity
                  </span>
              ) : null}
              {this.state.billingEntityValue ? (
                  <Tooltip
                    placement="top"
                    isOpen={this.state.billingEntityToolTipOpen}
                    target="billingEntityToolTip"
                    toggle={this.toggleBillingEntityToolTip}
                  >
                    {this.state.billingEntityValue.Name}
                  </Tooltip>
              ) : null}
            </div>
          </div>

        </div>

        <div className="main-seperator" />

        <div className="row">
          <div className="col-md-12">
            <Grid 
              width="100%" 
              data={orderBy(this.state.timeentries, this.state.sort).slice(
                this.state.skip,
                this.state.take + this.state.skip
              )}
              skip={this.state.skip}
              take={this.state.take}
              total={this.state.timeentries.length}
              pageable={true}
              onPageChange={this.pageChange}
              resizable
              sortable
              sort={this.state.sort}
              onSortChange={(e) => {
                this.setState({
                  sort: e.sort
                });
              }}
            >
              <Column field="ProjectName" title="Prject" />
              <Column field="LogDateTime" title="Date" />
              <Column field="Resource" title="Resource" />
              <Column field="ApprovedDuration" title="Duration"  />
              <Column field="ApprovedComment" title="Comment"  />
              <Column field="ApprovedTicketId" title="Ticket ID" />
              <Column field="RatePerHour" title="Rate Per Hour" />
              <Column field="Revenue" title="Revenue" />
            </Grid>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-3 btn-align-right">
            <Button 
              primary={true} 
              type="submit" 
              onClick={this.submitInvoiceRequestValidations} 
              disabled={this.state.isClickedApprovalBtn || this.props.location.action === 'view'}
            >
              Submit for Approval
            </Button>
            <Button 
              primary={true} 
              type="submit" 
              onClick={this.saveInvoiceRequest}
              disabled={this.props.location.action === 'view'}
            >
              Save
            </Button>
            <Button type="button" onClick={this.navigateToDashboard}>
              Cancel
            </Button>
          </div>
        </div>

        {this.state.visible === true && (
          <Dialog
              title={this.state.dialogTitle}
              onClose={this.toggleDialog}
              width="400px">
              
              {this.state.showSuccessImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
              )}

              {this.state.showErrorImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                  </div>
              )}

              <p style={{ margin: "25px", textAlign: "center" }}> {this.state.dialogMessage} </p>

              <DialogActionsBar>
                  <button
                      className="k-button modal-primary"
                      onClick={this.toggleDialog}>
                      OK
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.visibleSuccessPopUp === true && (
          <Dialog
              title={this.state.dialogTitle}
              onClose={this.navigateToIRSearch}
              width="400px">
              
              {this.state.showSuccessImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success"/>
                  </div>
              )}

              {this.state.showErrorImage === true && (
                  <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image"/>
                  </div>
              )}

              <p style={{ margin: "25px", textAlign: "center" }}> {this.state.dialogMessage} </p>

              <DialogActionsBar>
                  <button
                      className="k-button modal-primary"
                      onClick={this.navigateToIRSearch}>
                      OK
                  </button>
              </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showConfirmDialog === true && (
            <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
                <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
                <DialogActionsBar>
                    <button className="k-button" onClick={this.toggleConfirmDialog}>
                        No
                </button>
                    <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                        Yes
                </button>
                </DialogActionsBar>
            </Dialog>
        )}

        {this.state.showInvoiceRequstWorkflowSelectDialog === true && (
          <Dialog title="Select Workflow" onClose={this.toggleInvoiceRequestWorkflowSelectDialog} width="300px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select a workflow.</p>
                </div>
                <div className="row">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      {this.state.matchingWorkflowList.map((option, i) => {
                        return (
                            <div className="field-mod">
                              <input
                                  type="radio"
                                  id={'' + option.id}
                                  name={'' + option.id}
                                  value={option.id}
                                  checked={this.state.selectedWorkFlowId === option.id}
                                  className="k-radio"
                                  key={option.id}
                                  onChange={this.setSelectedWorkflowId.bind(this, option.id)}
                              />
                              <label
                                  key={option.id}
                                  htmlFor={'' + option.id}
                                  className="k-radio-label"
                                  title={"Approvers: " + option.approvers}
                              >
                                {option.name}
                              </label>
                            </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleInvoiceRequestWorkflowSelectDialog}>
                No
              </button>
              <button
                  className="k-button modal-primary"
                  onClick={this.setWorkflowInvoiceRequest.bind(this)}
                  disabled={!this.state.selectedWorkFlowId}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        <Loader loading={this.state.loading} />

      </div>
    );
  }
}

export default CreateInvoiceRequest;



